.form-section{
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
}
.send-btn{
  width: 261.3px;
  height: 42px;
  background-color: #b40000 !important;
  font-family: 'Open Sans';
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: 1.75px;
  color: #ffffff !important;
  border: 1px solid #b40000 !important;
  text-transform: uppercase;
  margin-top: 10px;
  border-radius: 0px !important;
}
.send-btn:hover{
  background-color: #ffffff !important;
  color: #b40000 !important;
  display: inline-block;
}
.label-contact{
  font-family: 'Open Sans' !important;
  font-size: 12px !important;
  line-height: 1.33 !important;
  color: #102746 !important;
}
.input-text-style{
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #444444;
}
.contactus-main-title{
  display: none;
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
}
@media(max-width:767px){
  .contactus-main-title{
    display: block;
  }
}
