.about-container{
  width: 100%;
}
.about-inner-text {
  max-width: 92% !important;
  height: 69%;
  display: table;
}
.about-main-title{
 display: table-cell;
 vertical-align: bottom;
}
.about-title{
  font-family: 'Open Sans';
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}
.about-subtitle{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

}
.about-subtitles{
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
 
  margin-top: 20px;
}
.about-section{
  max-width: 90% !important;
  box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
  background-color: #ffffff;
  margin-top: -95px;
  position: relative;
  z-index: 9;
}
.about-service-padding{
  padding: 86px 50px;
}
.about-service{
  width: 87%;
}
.about-titles{
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-top: 15px;
  text-align: left;
}
.about-desc{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-top: 15px;
  text-align: left;
}
.width-100-services{
  width: 100%;
}
.about-meat-team-section{
  margin-top: 89px;
  max-width: 92% !important;
}
.team-main-title{
  font-family: 'Open Sans';
  font-size: 45px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1e1e1e;
  text-align: center;
  margin-bottom: 41px;
}
.about-team{
  margin-top: 22px;
  width: 100%;
}
.about-team-img{
  width: 100%;
}
.about-team-title{
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.14px;
  color: #1d212c;
  text-align: center;
  margin-top: 21px;
  text-transform: uppercase;
}
.role-section{
  text-align: center;
}
.about-team-desc{
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: inline-block;
  margin-left: 10px;
}
.space{
  width: 100%;
  margin-top: 50px;
  padding-top: 28px;
}
.service-width{
  height: 48px;
}

@media(max-width:1300px){

}
@media(max-width:1200px){

}

@media(max-width:1100px){

}
@media(max-width:1023px){

}
@media(max-width:767px){
  .about-inner-text {
    max-width: 100% !important;
    padding: 2% 5% !important;
    height: 85%;
}
  .about-section {
    max-width: 100% !important;
    padding: 2% 5% !important;
    box-shadow: unset;
    background-color: #ffffff;
    margin-top: 0px;
}
.about-service-padding{
      padding: 0px 0px;
      margin-top: 50px;
 }
 .about-meat-team-section {
    margin-top: 70px;
    max-width: 100% !important;
 }
 .about-service {
    width: 100%;
    text-align: center !important;
    margin-bottom: 20px;
}
.fa-bars{
  font-size:23px;
  color: #ffffff;
}
.about-desc{
  text-align: left;
}
}
@media(max-width:720px){

}
@media(max-width:479px){

}

@media(max-width:419px){
 .team-main-title{
   font-size: 42px;
 }
}

@media(max-width:359px){

}
