.Dassai-Brewery {
  width: 178px;
  height: 21px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.05;
  letter-spacing: normal;
  color: #102746;
}
.userInformation{
  width: 325px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 10px 10px;
}
.user-title{
    font-family: Avenir;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: #102746;
}
.user-subtitle{
  font-family: Avenir !important;
   font-size: 18px;
   font-weight: 900;
   font-style: normal;
   font-stretch: normal;
   line-height: 1.33;
   letter-spacing: normal;
   color: #102746 !important;
}
.tour-details{
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #565656;text-transform: uppercase;
  cursor: pointer;
}
.m-l-10{
  margin-left: 10px;
}
