 .slider-img{
  height: 288px;
  /* height: 429px; */
  object-fit: cover;
  /* width: 100%; */
  width: 358px;
}
.slider-box {
  position: relative;
}
.brewery-sub-title{
  position: absolute;
  bottom: 0%;
  left: 0%;
  /* text-align: left; */
  width: 358px;
  background-color: rgba(21, 24, 36, 0.6);
  padding: 3% 0% 5% 6%;

}
.slick-slide img{
  display: unset !important;
}
.arrow-img-size{
  width: 25px;
  height: 25px;
}
.custome-width-slider-left{
  margin-right: 14px;
  /* padding-right: 14px; */
  boreder: whitesmoke;
  /* height: 428.8px; */
  position: relative;

}

.inner-slider-title{
  font-family: 'Open Sans';
   font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1.29px;
  color: #ffffff;
}
.inner-slider-subtitle{
   font-family: 'Open Sans';
   font-size: 14px;
   font-weight: 300;
   font-style: normal;
   font-stretch: normal;
   line-height: 1.43;
   letter-spacing: 1px;
   color: #ffffff;
   margin-bottom: 0px;
   width: 333px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slick-next:before, .slick-prev:before {
    display: none !important;
}
.img-brewery img{
  width: 100%;
}
.slick-prev {
    position: absolute;
    top: -22px !important;
    right: 94px !important;
    left: unset !important;
}
.slick-next{
  position: absolute;
  top: -22px !important;
  right: 4px !important;
  left: unset !important;
}
.slick-dots {
    bottom: -58px !important;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    left: -10px;
}
.prev-next-btn button{
  margin: 0px 20px;
  display: inline-block;
  background-color: unset !important;
  border: none;
  outline: none !important;
}
.slick-dots li button:before {
  font-size: 8px;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #b40000 !important ;
    font-size: 11px;
}
.prev-next-btn {
    margin-top: 8px;
    text-align: right;
    position: relative;
    top: -63px;
    right: 2.5%;
}
.space-right{
  margin: 0px 15px;
}
.slider-row-margin{
  margin-left: 0px  !important;
}
.brewery-slider-main-section{

}
.slider-padding-none{
  padding-left: 0px !important;

}
.brewery-slider-main-sections{

}

@media(max-width:1400px){
  /* .prev-next-btn {
      margin-top: 8px;
      text-align: right;
      position: relative;
      top: -90px;
      right: 3%;
  } */
}
@media(max-width:1300px){
  /* .prev-next-btn {
      margin-top: 8px;
      text-align: right;
      position: relative;
      top: -85px;
      right: 3%;
  } */
}
@media(max-width:1200px){
  /* .prev-next-btn {
      margin-top: 8px;
      text-align: right;
      position: relative;
      top: -85px;
      right: 3%;
  } */
}
@media(max-width:1100px){
  /* .prev-next-btn {
      margin-top: 8px;
      text-align: right;
      position: relative;
      top: -76px;
      right: 3%;
  } */
}
@media(max-width:1023px){

/* .prev-next-btn {
    margin-top: 8px;
    text-align: right;
    position: relative;
    top: -66px;
    right: 3%;
} */
/* .prev-next-btn {
    margin-top: 8px;
    text-align: right;
    position: relative;
    top: -86px;
    right: 5%;
} */
}
@media(max-width:767px){
  .slider-padding-none{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .custome-width-slider-left{
    margin-right: 0px;
    padding-right: 0px
  }
  .arrow-img-size{
    width: 22px;
    height: 22px;
  }
  .prev-next-btn {
    margin-top: 8px;
    text-align: right;
    position: relative;
    top: -68px;
    right: 10%;
  }
  .slider-img{
    height: 310px;
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
  .right-arrow-size{
  }
  .slider-row-margin{
    margin-left: -15px  !important;
  }
  .brewery-sub-title{
    bottom: 0%;
    left: 0%;
    text-align: left;
    width: 100%;
    padding: 3% 0% 5% 6%;
  }
}
@media(max-width:719px){
    .prev-next-btn button {
        margin: 0px 10px;
        display: inline-block;
        background-color: unset !important;
        border: none;
        outline: none !important;
    }
    .arrow-img-size{
      width: 15px;
      height: 15px;
    }
    .prev-next-btn {
      right: 16%;
      top: -60px;
   }

}
@media(max-width:479px){
  .prev-next-btn {
    right: 16%;
    top: -57px;
 }

}
@media(max-width:419px){
 .prev-next-btn button {
    margin: 0px 4px;
 }
 /* .prev-next-btn {
     margin-top: 8px;
     text-align: right;
     position: relative;
     top: -56px;
     right: 22%;
 } */
}
@media(max-width:359px){

}
