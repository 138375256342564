@font-face {
  font-family: 'PingFangSC';
  src: url('../../customeFont/font/PingFang-SC-Thin.ttf') format('truetype');

  font-weight: 400;
  font-style: normal;
}
.font-style{
  font-family: 'PingFangSC';
}
.what-is-sake-container{
    width: 100%;
}
.whatIsSake-inner-text {
  max-width: 92% !important;
  height: 79%;
  display: table;
}
.whatIsSake-main-title{
 display: table-cell;
 vertical-align: bottom;
}

.whatIsSake-subtitle{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 40%;
}
.whatIsSake-title{
  font-family: 'Open Sans';
 font-size: 60px;
 font-weight: 300;
 font-style: normal;
 font-stretch: normal;
 line-height: 1.2;
 letter-spacing: normal;
 color: #ffffff;
 width: 40%;
}
.whatIsSake-subtitles{
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  width: 40%;
  margin-top: 20px;
}
.making-section{
  max-width: 92% !important;
  margin-top: 66px;
}
.making-row{
  margin-top: 20px;
  padding-top: 20px;
}
.text-making{
  width: 93%;
}
.making-heading{
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #15181f;
}
.making-title{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-top: 25px;
  text-align: justify;
}
.making-subtitles{
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-bottom: 31px;
  text-align: justify;
}
.making-img-section{
  margin-top: 91px;
}
.making-img{
  width: 100%;
  height: 396px;
}
.production-section{
  max-width: 92% !important;
  margin-top: 0px;
}
.production-row{
  margin-top: 0px;
  padding-top: 0px;
}
.text-production{
  width: 95%;
}
.production-heading{
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #15181f;
}
.production-title{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-top: 25px;
  text-align: justify;
}
.production-subtitles{
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #1e1e1e;
  margin-bottom: 31px;
  text-align: justify;
}
.production-img-section{
  margin-top: 17px;
  width: 93%;
}
.production-img {
    width: 100%;
    height: 396px;
}
.varieties-section{
  max-width: 92% !important;
  margin-bottom: 60px;
  margin-top: 30px;
}
.varieties-title{
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #15181f;
}
.varieties-table{
   background-color: #f5f5f5;
   margin-top: 40px;
   padding: 50px 50px 10px 50px;
}
table{
  width: 100%;
}
tr{
  width: 100%;
  border-bottom: solid 2px #d8d4d4;
}

tr:last-child{
  border-bottom: solid 0px ;
}
.table-heading-border{
  border-bottom: solid 2px #d8d4d4 !important;
}
.table-custome-width{
  width: 40%;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1e1e1e;
  text-transform: uppercase;
}
.table-heading{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1e1e1e;
  text-transform: uppercase;
}
.custome-width-border{
  width: 44% !important;
}
.table-items td{
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1e1e1e;
}
.table th, .table td {
  padding-left: 0px !important;
}
.production-img-section-display-mobile{
  display: none;
}
.production-img-section-mobile-none{
  display: block;
}
.varieties-title-main-section{
  width: 90%;
  margin: 0 auto;
}
.varieties-table-scrolling{

}
@media(max-width:1300px){
  .table-heading{
    font-size: 16px;
  }
  .table-items td{
    font-size: 14px;
  }
}
@media(max-width:1200px){
  .table-heading{
    font-size: 16px;
  }
  .table-items td{
    font-size: 12px;
  }
}
@media(max-width:1100px){
  .table-heading{
    font-size: 15px;
  }
  .table-items td {
    font-size: 10px;
 }
}
@media(max-width:1023px){

}
@media(max-width:767px){
  .whatIsSake-inner-text {
    max-width: 100% !important;
    height: 79%;
    padding: 2% 5% !important;
   }
   .making-section{
       max-width: 100% !important;
       padding: 2% 5% !important;
       margin-top: 10px;
   }
     .text-making {
        width: 100%;
    }
    .whatIsSake-subtitle{
      width: 100%;
    }
    .whatIsSake-title {
      font-size: 36px;
      width: 100%;
   }
   .whatIsSake-subtitles{
     font-size: 18px;
     width: 100%;
     text-align: left;
   }
   .making-title{
     font-size: 18px;
     text-align: left;
   }
   .making-subtitles{
     font-size: 18px;
     text-align: left;
   }
   .making-img-section {
      margin-top: 40px;
   }
   .production-section {
      max-width: 100% !important;
      margin-top: 0px;
      padding: 2% 5% !important;
   }
   .production-img-section-display-mobile{
     display: block;
   }
   .production-img-section-mobile-none{
     display: none;
   }
   .text-production {
      width: 100%;
  }
  .production-title{
    font-size: 24px;
  }
  .production-subtitles{
    font-size: 18px;
    text-align: left;
  }
  .production-img-section {
      margin-top: 17px;
      width: 100%;
  }
  .varieties-section {
    max-width: 100% !important;
    margin-bottom: 20px;
    margin-top: 6px;
    padding: 2% 0% !important;
 }
 .varieties-title{
   font-size: 30px;
   margin-top: 48px;
   }
   .table-heading {
      font-size: 18px;
  }
  .table-items td{
    font-size: 18px;
  }
  .making-img{
    width: 100%;
    height: 396px;
    object-fit: cover;
  }
 .varieties-table-scrolling{
    overflow-x: scroll;
    width: 90%;
    margin: 0 auto;
  }
  .varieties-table{
    padding: 0px 50px 0px 50px;
    margin-top: 15px;
    width: max-content;
  }
  table thead{
    background-color: #f5f5f5;
  }
  table tbody{
    background-color: #f5f5f5;
  }
  .border-bottom{
    display: none;
  }
}
