.products-container{
    width: 100%;
}
.products-inner-text {
  max-width: 92% !important;
  height: 77%;
  display: table;
}
.products-main-title{
 display: table-cell;
 vertical-align: bottom;
}

.products-subtitle{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 40%;
  text-transform: uppercase;
}
.products-title{
  font-family: 'Open Sans';
 font-size: 60px;
 font-weight: 300;
 font-style: normal;
 font-stretch: normal;
 line-height: 1.2;
 letter-spacing: normal;
 color: #ffffff;
 width: 40%;
}
.products-subtitles{
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  width: 40%;
  margin-top: 25px;
}
.products-select-section{
  max-width: 92% !important;
  margin-top: 50px;
}
.select-brewery-width{
  cursor: pointer;

}
.clearfix::after{
 content: "";
 display: table;
 clear: both;
}

.select-brewery{
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  background-color: white;
  margin-bottom: 20px;
  float: right;
  width: 16%;
  padding-bottom: 10px;
  border: none !important;
  border-bottom: solid 2px grey !important;
  cursor: pointer;
  outline: none;
  padding-left: 5px;
}
.select-brewery option{
  background-color: #ffffff;
  padding: 10px 10px;
  cursor: pointer !important;
  color:#444444 !important;
  text-transform: uppercase;
}

.products-category-list{
  max-width: 92% !important;
  margin-top: 20px;
}
.category-list{
  margin-top: 15px;
}
.product-space{
  margin-top: 30px;
  padding-top: 30px;
}
.display-select-option-desktop{
  display: block;
}
.display-select-option-mobile{
  display: none;
}
.category-mobile-none{
  display: block;
}
.category-mobile-block{
  display: none
}
@media(max-width:1300px){
  .select-brewery {
    width: 20%;
  }
}
@media(max-width:1200px){
  .select-brewery{
    width: 20%;
  }
}
@media(max-width:1100px){
  .select-brewery {
    width: 27%;
  }
}
@media(max-width:1023px){

}
@media(max-width:767px){
  .products-title{
    width: 100%;
  }
  .products-subtitle{
    width: 100%;
  }
  .products-subtitles{
    width: 100%;
  }
  .display-select-option-desktop{
    display: none;
  }
  .display-select-option-mobile{
    display: block;
  }
  .select-brewery {
    width: 100%;
    float: none;
 }
 .category-mobile-none{
   display: none;
 }
 .category-mobile-block{
   display: block;
 }
 .margin-mobile-ctaegory{
   margin-top: 30px;
 }
 .margin-select-list{
    margin-top: 30px;
 }
}
