.arrow-img-size{
  width: 30px;
  height: 30px;
}



@media(max-width:767px){

 .arrow-img-size {
    width: 22px;
    height: 22px;
 }
}
@media(max-width:719px){

}
@media(max-width:479px){


}
@media(max-width:419px){

}
