.contact-container{
    width: 100%;
}
.contact-inner-text {
  max-width: 92% !important;
  height: 69%;
  display: table;
}
.address-section{
  margin-top: 15px;
}
.contact-main-title{
 display: table-cell;
 vertical-align: bottom;
}
.contact-title{
  font-family: 'Open Sans';
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}
.contact-subtitle{
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

}
.contact-subtitles{
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
 
  margin-top: 20px;
}
.contact-section{
  max-width: 90% !important;
  box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
  background-color: #ffffff;
  margin-top: -95px;
  position: relative;
  z-index: 9;
}
.contact-address-padding{
  padding: 70px 50px 120px 50px;
}
.address-main-title{
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  color: #1e1e1e;
}
.address{
  list-style-type: none;
  padding: 0px;
  margin: 27px 0px 0px 0px;
}
.address li{
  display:block;
  color: #1e1e1e;
}
.address li a{
  display:block;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #1e1e1e;
  cursor: pointer;
}
.new-web{
  color: #b40000 !important;
}
.map{
  margin-top: 40px;
  height: 339px;
  width: 100%;
  opacity: 0.81;
  position: relative;
}
.contact-img{
  width: 380px;
  height: 339px;
}
.contact-form-display-mobile{
  display: none;
}
.contact-form-display-desktop{
  display: block;
}
@media(max-width:1300px){
  .contact-img {
    width: 334px;
    height: 339px;
  }
}
@media(max-width:1200px){
  .contact-img {
    width: 317px;
    height: 339px;
  }
}
@media(max-width:1100px){
  .contact-img {
    width: 300px;
    height: 339px;
  }
}
@media(max-width:1023px){
  .contact-img {
    width: 300px;
    height: 339px;
  }
}
@media(max-width:767px){
  .contact-inner-text {
    max-width: 100% !important;
    padding: 2% 5% !important;
    height: 85%;
}
.contact-section {
    max-width: 100% !important;
    box-shadow: unset;
    margin-top: 0px;
    padding: 2% 5% !important;
}
.contact-address-padding {
  padding: 50px 0px 120px;
  margin-top: 0px;
 }
 .contact-form-display-mobile{
   display: block;
 }
 .contact-form-display-desktop{
   display: none;
 }
 .address-section {
    margin-top: 30px;
 }
 .send-btn{
   width: 100% !important;
 }
 .map{
   width: 100%;
   position: relative;
 }
}
