.footer {
  width: 100%;
}

.footer-top {
  width: 93%;
  margin: 0 auto;
  height: 90px;
  padding-top: 32px;
}

.footer-list {
  width: 60%;
  float: left;
}

.footer-right {
  width: 40%;
  float: left;
  text-align: right;
}

.footer-list-items {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer-list-items li {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.footer-list-items li a {
  padding: 10px 10px;
  margin: 10px 10px;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.75px;
  text-align: center;
  color: #aaaeb8;
}

.footer-list-items li a:hover {
  text-decoration: underline;
  color: #aaaeb8;
}

.footer-right-social {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer-right-social li {
  display: inline-block;
}

.footer-right-social li a {
  padding: 10px 10px;
  margin: 10px 10px;
  text-transform: uppercase;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.footer-bootom {
  width: 100%;
  background-color: #1f2126;
}

.footer-desc {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9fa4af;
  margin-bottom: 0px;
  padding: 20px 0px;
  width: 100%;
  text-align: center;
  position: relative;
}

.footer-desc div {
  position: absolute !important;
  bottom: 20px !important;
  right: 60px !important;
}

.move-icons {
  cursor: pointer;
  text-align: left;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  left: 35%;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.desktop-social-icons {
  display: block;
}

.mobile-social-icons {
  display: none;
}

.display-desktop {
  display: block;
}

.display-mobile {
  display: none;
}

.active-footer {
  color: #181c27 !important;
}

.mobile-size-socail {
  width: 24px;
  height: 24px;
}

@media (max-width: 1300px) {
  .footer-list {
    width: 63%;
  }

  .footer-right {
    width: 36%;
  }

  .footer-desc div {
    position: absolute !important;
    bottom: 20px !important;
    right: 65px !important;
  }
}

@media (max-width: 1200px) {
  .footer-list {
    width: 66%;
  }

  .footer-right {
    width: 34%;
  }

  .footer-desc div {
    position: absolute !important;
    bottom: 20px !important;
    right: 50px !important;
  }
}

@media (max-width: 1100px) {
  .footer-list {
    width: 71%;
  }

  .footer-right {
    width: 29%;
  }

  .footer-desc div {
    position: absolute !important;
    bottom: 20px !important;
    right: 50px !important;
  }
}

@media (max-width: 1023px) {
  .footer-list {
    width: 71%;
  }

  .footer-right {
    width: 29%;
  }

  .footer-list-items li a {
    padding: 10px 4px;
    margin: 10px 5px;
    font-size: 10px;
  }

  .footer-right-social li a {
    padding: 10px 8px;
    margin: 10px 8px;
  }

  .footer-desc div {
    position: absolute !important;
    bottom: 20px !important;
    right: 40px !important;
  }
}

@media (max-width: 767px) {
  .footer-list-item-selected {
    color: #181c27;
  }

  .footer-top {
    height: unset;
    padding-bottom: 40px;
  }

  .footer-list {
    width: 100%;
    float: none;
  }

  .footer-right {
    width: 100%;
    float: none;
    text-align: center;
  }

  .footer-list-items li {
    display: block;
    text-align: center;
  }

  .footer-list-items li a {
    display: block;
    font-size: 18px;
    text-align: center;
    padding: 16px 4px;
  }

  .footer-right-social li {
    display: block;
  }

  .footer-right-social li a {
    padding: 16px 4px;
    margin: 10px 8px;
    display: block;
  }

  .desktop-social-icons {
    display: none;
  }

  .mobile-social-icons {
    display: block;
  }

  .footer-desc {
    padding: 40px 0px;
    width: 100%;
    text-align: center;
  }

  .footer-desc div {
    position: absolute !important;
    bottom: 45px !important;
    right: 20px !important;
  }

  .display-desktop {
    display: none;
  }

  .display-mobile {
    display: block;
  }
}

@media (max-width: 719px) {
  .footer-desc div {
    position: absolute !important;
    bottom: 45px !important;
    right: 20px !important;
  }
}

@media (max-width: 479px) {
  .footer-desc div {
    position: absolute !important;
    bottom: 65px !important;
    right: 20px !important;
  }
}