.youTubeVideo {
  width: 100%;
  height: 609px !important;

}


#background-video{
  cursor: pointer;
}
.player-wrapper {
  position: relative;
  padding-top: 5%;
  padding-bottom: 1% ;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
