body,html{
  margin:0px;
  padding: 0px;
}

@keyframes zoominout {
    0% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(1.3,1.3);
    }
}
  .header-img-section{
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .banner{
    height: 948px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;

  }
  .banner-overlay-content{
    position: absolute;
    top: 0px;
    width: 100%;
    /* box-shadow: inset 0 0 0 689px rgba(21,24,36,.5); */
    height: 948px;
      /* opacity: .6; */
  }
  .about-banner{
    height: 838px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;
  }
  .about-banner-overlay-content{
    position: absolute;
    top: 0px;
    height: 738px;
    width: 100%;
  }
  .product-banner{
    height: 736px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;
  }
  .product-banner-overlay-content{
    position: absolute;
    top: 0px;
    height: 736px;
    width: 100%;
  }
  .what-is-sake-banner{
    height: 700px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;
  }
  .what-is-sake-banner-overlay-content{
    position: absolute;
    top: 0px;
    height: 700px;
    width: 100%;
  }
  .contact-banner{
    height: 736px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;
  }
  .contact-banner-overlay-content{
    position: absolute;
    top: 0px;
    height: 736px;
    width: 100%;
  }

  .breweries-banner-details{
    height: 736px;
    width: 100%;
    animation-name: zoominout;
    animation-duration: 30s;
    animation-iteration-count: 1;
    animation-direction:normal;
    object-position:center;
    object-fit: cover;
  }
  .breweries-banner-details-overlay-content{
    position: absolute;
    top: 0px;
    height: 736px;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(21, 24, 36, 0.6);
  }




/* .mobile-bg-home{
  height: 948px;
  width: 100%;
  background-image: url(../images/mobile/background-image.png);
  object-fit: cover;
  display: none;
}
.bg-home-page{
  height: 948px;
  width: 100%;
  background-image: url(../images/homebackground@3x.png);
  object-fit: cover;
  display: block;
} */

/* .about-banner{
  height: 838px;
  max-width:100%;
  color: #fff;
  background-image: url(../images/image-background-about.png);
  object-fit: cover;
} */
/* .contact-banner{
  height: 948px;
  max-width:100%;
  color: #fff;
  background-image: url(../images/background-contact@2x.png);
  object-fit: contain;
}
.what-is-sake-banner{
  display: none;
}
.what-is-sake-banner{
  height:700px;
  max-width:100%;
  color: #fff;
  background-image: url(../images/homebackground-whatisSake@3x.png);
  object-fit: cover;
  display: block;
}
.breweries-banner{
  height: 736px;
  max-width:100%;
  color: #fff;
  background-image: url(../images/image-background-brewery.png);
  object-fit: cover;
  display: block;
}
.breweries-banner-details{
  height: 736px;
  max-width:100%;
  color: #fff;
  box-shadow: inset 0 0 0 1000px rgba(21, 24, 36, 0.6);
  background-image: url(../images/brewery-details-bg@2x.png);
  object-fit: cover;
}
.product-banner-mobile{
  display: none;
}

.products-banner{
  height: 736px;
  max-width:100%;
  color: #fff;
  background-image: url(../images/product-background-image.png);
  object-fit: cover;
  display: block;
} */

.bg-img-home-page{
  background-image: url(../images/homebackground.png);
  max-width:100%;
  object-fit: cover;
  height: 435px;
  background-position: center;
}

.carousel-control-next-icon {
  background-image: url(../images/icons8-right-28.png) !important;
  /* transform: rotate(180deg); */
}
.carousel-control-prev-icon{
   background-image: url(../images/icons8-left-24.png) !important;
}
.nextIcon{
  background-image: url(../images/icons8-right-28.png) !important;
}

.next-icon-disabled{
  background-image: url(../images/icons8-left-24.png) !important;
  transform: rotate(180deg);
}
.arrow-image-size{
  width: 20px;
  height: 20px;
}

.product-img-size{
  width: 202.4px;
  height: 202.5px;
  object-fit: contain;
}
.thumb-img-size{
  width: 122px;
  height: 122px;
  object-fit: cover;
}
.error{
  color: red;
  font-family: Montserrat;
  font-size: 14px;
  margin-top: 2px;
}
@media(max-width:767px){
  .logo-mobile-img{
    width: 119px;
    height: 46px
  }
  /* .bg-home-page{
    height: 948px;
    width: 100%;
    background-image: url(../images/homebackground@3x.png);
    object-fit: cover;
    display: none;
  }
  .mobile-bg-home{
    height: 948px;
    width: 100%;
    background-image: url(../images/mobile/background-image.png);
    object-fit: cover;
    display: block;
  } */
  /* .products-banner{
    display: none;
  }
  .product-banner-mobile{
    height: 680px;
    max-width:100%;
    color: #fff;
    background-image: url(../images/mobile/background-product-image.png);
    object-fit: cover;
    display: block;
  }

  .what-is-sake-banner{
    display: none;
  }
  .what-is-sake-banner{
    height:680px;
    max-width:100%;
    color: #fff;
    background-image: url(../images/mobile/whatissake.png);
    object-fit: cover;
    display: block;
  } */

}

@media(max-width:719px){

}
@media(max-width:479px){

}
@media(max-width:419px){

}
@media(max-width:359px){

}
