.products-list-items{
  text-align: left;
  margin-top: 30px;
  text-align: center;
}
.products-list-text-align{
  padding: 0px !important;
}
.products-list-items-img-size{
  text-align: left;
}
.products-list-items-title{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 1.14px;
    color: #1d212c;
    margin-top: 20px;
    text-align: left;
    text-transform: uppercase;
    width: 95%;
}
.products-list-items-desc{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    width: 95%;
    height: 59px;
    min-height: 59px;
    overflow: hidden;
}
@media(max-width:1300px){
  .products-list-items-title{
    font-size: 14px;
  }
}
@media(max-width:1200px){
  .products-list-items-title {
    font-size: 12px;
 }
}
@media(max-width:1100px){
  .products-list-items-title {
    font-size: 11px;
  }
}
@media(max-width:1023px){
  .products-list-items-title {
    font-size: 11px;
  }
  .products-list-items-img-size{
    width: 100%;
  }
}
@media(max-width:767px){
  .product-img-size{
    width: 100%;
    height: 202.5px;
    object-fit: contain;
  }
}
